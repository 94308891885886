html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 230, 223, 1);
  overflow-x: hidden;
  overflow-y: auto;
}

#root {
  height: 100%;
}
